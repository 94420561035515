import { User } from '../models/user'
import { DbController } from './DbController'

export class RootFactory {
    private version: number = 1
    private dbName: string = 'borb_db'
    private userTableName: string = 'users'
    private weightHistoryTableName: string = 'weight_history'
    private dbController: DbController

    constructor() {
        this.dbController = new DbController(this.dbName)
    }

    public createObjectStore(): Promise<boolean> {
        return this.dbController.createObjectStore([
            this.userTableName,
            this.weightHistoryTableName,
        ])
    }

    public putUser(user: User): Promise<number> {
        return this.dbController.putValue(this.userTableName, user)
    }

    public getUser(id: number): Promise<User> {
        return this.dbController.getValue(this.userTableName, id)
    }

    public getAllUsers(): Promise<User[]> {
        return this.dbController.getAllValue(this.userTableName)
    }

    public putBulkValues(tableName: string, values: object[]) {
        return this.dbController.putBulkValues(tableName, values)
    }

    public deleteValue(tableName: string, id: number) {
        return id
    }

    public deleteDatabase() {
        this.dbController.deleteDatabase()
    }

    public backupDatabase() {
        //await deleteDB(this.name)
    }

    public restoreDatabase() {
        //await deleteDB(this.name)
    }
}

export const RootFactoryObject = new RootFactory()
