import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
    Button,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Slider,
    TextField,
} from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { UserMacros } from '../userMacros/userMacros.component'

import { PageRootContext } from '../../pageContext'
import { getDefaultUser, User } from '../../models/user'
import {
    convertInchesToCentemeters,
    convertCentemetersToInches,
    convertPoundsToKilograms,
    convertKilogramsToPounds,
} from '../../utils/converter'
import {
    genderEnum,
    hoursOfExerciseEnum,
    macroGoalEnum,
} from '../../models/enums'
import {
    calculateBasalMetabolicRate,
    calculateTotalDailyEnergyExpenditure,
    calculateDailyMacros,
    calculateCalories,
    calculateProtein,
    calculateCarbs,
    calculateFats,
} from '../../utils/calculator'

interface UserProfileProps {
    createUser: boolean
}

export const UserProfile = observer((props: UserProfileProps) => {
    const context = useContext(PageRootContext)
    const rootStore = context?.rootStore

    let [editUser, setEditUser] = useState(props.createUser)
    let [user, setUser] = useState<User>(
        rootStore?.user ? rootStore.user : getDefaultUser(),
    )
    let [hoursOfExercise, setHoursOfExercise] = useState(
        rootStore?.user?.hoursOfExercise
            ? rootStore.user.hoursOfExercise
            : hoursOfExerciseEnum.none,
    )

    useEffect(() => {
        if (rootStore?.user) setUser(rootStore.user)
    }, [rootStore?.user])

    useEffect(() => {
        if (rootStore?.user?.hoursOfExercise)
            setHoursOfExercise(rootStore.user.hoursOfExercise)
    }, [rootStore?.user?.hoursOfExercise])

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 1,
            label: '1 to 3',
        },
        {
            value: 2,
            label: '4 to 6',
        },
        {
            value: 3,
            label: '7 to 9',
        },
        {
            value: 4,
            label: '10+',
        },
    ]

    const handleOnChangeGender = (event: SelectChangeEvent<genderEnum>) => {
        let updateUser = { ...user }
        updateUser.gender = event.target.value as genderEnum
        setUser(updateUser)
    }

    const handleOnSave = () => {
        let updateUser = { ...user }
        updateUser.name = (
            document.getElementById('user-name') as HTMLInputElement
        )?.value
        updateUser.age = parseInt(
            (document.getElementById('user-age') as HTMLInputElement)?.value,
        )
        const userHeightInches = parseInt(
            (document.getElementById('user-height-inches') as HTMLInputElement)
                ?.value,
        )
        updateUser.height = convertInchesToCentemeters(userHeightInches)
        const userWeightPounds = parseFloat(
            (document.getElementById('user-weight') as HTMLInputElement)?.value,
        )
        updateUser.weight = convertPoundsToKilograms(userWeightPounds)
        updateUser.bmr = calculateBasalMetabolicRate(
            updateUser.height,
            updateUser.weight,
            updateUser.age,
            updateUser.gender,
        )
        updateUser.hoursOfExercise = hoursOfExercise
        updateUser.tdee = calculateTotalDailyEnergyExpenditure(
            updateUser.bmr,
            hoursOfExercise,
        )
        updateUser.calories = calculateCalories(
            updateUser.tdee,
            updateUser.macroGoal,
        )
        updateUser.protein = calculateProtein(
            updateUser.calories,
            updateUser.macroGoal,
        )
        updateUser.carbs = calculateCarbs(
            updateUser.calories,
            updateUser.macroGoal,
        )
        updateUser.fats = calculateFats(
            updateUser.calories,
            updateUser.macroGoal,
        )

        setUser(updateUser)
        rootStore?.setUser(updateUser, true)
    }

    const onSliderChange = (event: Event, newValue: number | number[]) => {
        switch (newValue) {
            case 1:
                setHoursOfExercise(hoursOfExerciseEnum.oneToThree)
                break
            case 2:
                setHoursOfExercise(hoursOfExerciseEnum.fourToSix)
                break
            case 3:
                setHoursOfExercise(hoursOfExerciseEnum.sevenToNine)
                break
            case 4:
                setHoursOfExercise(hoursOfExerciseEnum.tenPlus)
                break
            default:
                setHoursOfExercise(hoursOfExerciseEnum.none)
                break
        }
    }

    return (
        <>
            <Paper className="user" elevation={3}>
                <h3>{props.createUser ? 'Create User' : 'User'}</h3>
                <Grid container spacing={2}>
                    <Grid item xs={8} md={8}>
                        <TextField
                            id="user-name"
                            label="User Name"
                            variant="outlined"
                            disabled={!editUser}
                            defaultValue={rootStore?.user?.name}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <FormControl>
                            <InputLabel id="user-gender-label">
                                Gender
                            </InputLabel>
                            <Select
                                labelId="user-gender-label"
                                id="user-gender"
                                label="Gender"
                                defaultValue={user.gender || undefined}
                                disabled={!editUser}
                                onChange={handleOnChangeGender}
                            >
                                <MenuItem value={undefined}></MenuItem>
                                <MenuItem value={genderEnum.male}>
                                    Male
                                </MenuItem>
                                <MenuItem value={genderEnum.female}>
                                    Female
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <TextField
                            id="user-age"
                            label="Age"
                            variant="outlined"
                            type="number"
                            disabled={!editUser}
                            defaultValue={rootStore?.user?.age}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <TextField
                            id="user-height-inches"
                            label="Height (inches)"
                            variant="outlined"
                            type="number"
                            disabled={!editUser}
                            defaultValue={convertCentemetersToInches(
                                rootStore?.user?.height,
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <TextField
                            id="user-weight"
                            label="Weight (lbs)"
                            variant="outlined"
                            type="number"
                            disabled={!editUser}
                            defaultValue={
                                convertKilogramsToPounds(user.weight) || null
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <InputLabel id="user-activity-label">
                            Hours of physical activity per week
                        </InputLabel>
                        <Slider
                            key={`slider-${user.hoursOfExercise}`}
                            aria-label="hours of exercise steps"
                            defaultValue={user.hoursOfExercise}
                            marks={marks}
                            step={1}
                            min={0}
                            max={4}
                            valueLabelDisplay="off"
                            className="slider"
                            onChange={onSliderChange}
                            disabled={!editUser}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} className="right-align">
                        {editUser ? (
                            <Fab
                                id="add-user-button"
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={handleOnSave}
                            >
                                <Add />
                                Save
                            </Fab>
                        ) : (
                            <Fab
                                id="edit-user-button"
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="edit"
                                onClick={() => {
                                    setEditUser(true)
                                }}
                            >
                                <Edit />
                                Edit
                            </Fab>
                        )}
                    </Grid>
                </Grid>
            </Paper>
            {rootStore?.user?.id ? (
                <>
                    <UserMacros></UserMacros>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} className="center-align">
                            <Button variant="contained">Done</Button>
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </>
    )
})
