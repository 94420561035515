export const convertInchesToCentemeters = (inches?: number): number => {
    return inches !== null && inches !== undefined ? inches * 2.54 : 0
}
export const convertCentemetersToInches = (cm?: number): number => {
    return cm !== null && cm !== undefined ? cm / 2.54 : 0
}
export const convertPoundsToKilograms = (pounds?: number): number => {
    return pounds !== null && pounds !== undefined ? pounds * 0.45359737 : 0
}
export const convertKilogramsToPounds = (kgs?: number): number => {
    return kgs !== null && kgs !== undefined ? kgs / 0.45359737 : 0
}
