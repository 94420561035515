import './App.scss'
import { PageContext } from './pageContext'
import { Root } from './components/root.component'

function App() {
    return (
        <PageContext>
            <Root></Root>
        </PageContext>
    )
}

export default App
