import { makeAutoObservable, observable, flow, action } from 'mobx'

import { RootFactoryObject, RootFactory } from '../factory/RootFactory'
import { getDefaultUser, User } from '../models/user'
import { macroGoalEnum } from '../models/enums'
import { calculateDailyMacros } from '../utils/calculator'
import { Macros } from '../models/macros'

export class RootStore {
    loading: boolean = false
    rootFactory: RootFactory
    user?: User = undefined
    dailyMacros: Macros[] = []

    private saveUser(user: User): void {
        this.setLoading(true)
        this.rootFactory
            .putUser(user)
            .then((id) => {
                if (this.user) {
                    this.setUser(
                        {
                            ...this.user,
                            ...{
                                id: id,
                            },
                        },
                        false,
                    )
                }
                this.setLoading(false)
            })
            .catch((error) => {
                console.error(error)
                this.setLoading(false)
            })
    }

    constructor() {
        this.rootFactory = RootFactoryObject

        makeAutoObservable(this, {
            loading: observable,
            setLoading: action,
            deleteDatabase: action,
            backupDatabase: action,
            restoreDatabase: action,
            user: observable,
            clearUser: action,
            setUser: action,
            loadUser: action,
            updateMacroGoal: action,
            updateCarbCycle: action,
        })

        this.rootFactory.createObjectStore().then((success) => {
            if (success) this.loadUser()
        })
    }

    setLoading(loading: boolean): void {
        this.loading = loading
    }

    deleteDatabase(): void {
        this.rootFactory.deleteDatabase()
    }

    backupDatabase(): void {}

    restoreDatabase(): void {}

    loadUser(): Promise<any> {
        this.setLoading(true)
        return this.rootFactory
            .getAllUsers()
            .then((results) => {
                if (results.length) {
                    this.setUser(results[0], false)
                    this.updateCarbCycle(results[0].carbCycle)
                }
                this.setLoading(false)
            })
            .catch((error) => {
                console.error(error)
                this.setLoading(false)
            })
    }

    clearUser() {
        this.user = undefined
    }

    setUser(user: User, saveUser: boolean) {
        this.user = user
        if (this.user && saveUser) {
            this.saveUser(this.user)
        }
    }

    updateMacroGoal(goal: macroGoalEnum): void {
        if (this.user) {
            this.user.macroGoal = goal
            this.dailyMacros = calculateDailyMacros(
                this.user.tdee,
                this.user.carbCycle,
                goal,
            )
            this.saveUser(this.user)
        }
    }

    updateCarbCycle(carbCycle: boolean): void {
        if (this.user) {
            this.user.carbCycle = carbCycle
            this.dailyMacros = calculateDailyMacros(
                this.user.tdee,
                carbCycle,
                this.user.macroGoal,
            )
            this.saveUser(this.user)
        }
    }
}
export const RootStoreObject = new RootStore()
