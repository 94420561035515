import {
    dayOfTheWeekEnum,
    genderEnum,
    hoursOfExerciseEnum,
    macroGoalEnum,
} from '../models/enums'
import { Macros } from '../models/macros'
import { User } from '../models/user'
import { enumKeys } from './helper'

export const calculateBasalMetabolicRate = (
    height: number,
    weight: number,
    age: number,
    gender?: genderEnum,
): number => {
    if (gender === genderEnum.male) {
        return 10 * weight + 6.25 * height - 5 * age + 5
    } else {
        return 10 * weight + 6.25 * height - 5 * age - 161
    }
}

export const calculateTotalDailyEnergyExpenditure = (
    bmr: number,
    hoursOfExercise: hoursOfExerciseEnum,
): number => {
    switch (hoursOfExercise) {
        case hoursOfExerciseEnum.oneToThree:
            return bmr * 1.2 //1.35
        case hoursOfExerciseEnum.fourToSix:
            return bmr * 1.4 //1.55
        case hoursOfExerciseEnum.sevenToNine:
            return bmr * 1.6 //1.75
        case hoursOfExerciseEnum.tenPlus:
            return bmr * 1.8 //1.95
        default:
            return bmr * 1.15
    }
}

export const calculateCalories = (
    tdee: number,
    macroGoal?: macroGoalEnum,
): number => {
    let cals = 0
    switch (macroGoal) {
        case macroGoalEnum.cutting:
            cals = tdee * 0.75
            break
        case macroGoalEnum.bulking:
            cals = tdee * 1.1
            break
        default:
            cals = tdee
            break
    }
    return Number(cals.toFixed(0))
}

export const calculateProtein = (
    calories: number,
    macroGoal?: macroGoalEnum,
): number => {
    let protein = 0
    switch (macroGoal) {
        case macroGoalEnum.cutting:
            protein = (calories * 0.4) / 4
            break
        case macroGoalEnum.bulking:
            protein = (calories * 0.25) / 4
            break
        default:
            protein = (calories * 0.3) / 4
            break
    }
    return Number(protein.toFixed(0))
}

export const calculateCarbs = (
    calories: number,
    macroGoal?: macroGoalEnum,
): number => {
    let carbs = 0
    switch (macroGoal) {
        case macroGoalEnum.cutting:
            carbs = (calories * 0.4) / 4
            break
        case macroGoalEnum.bulking:
            carbs = (calories * 0.55) / 4
            break
        default:
            carbs = (calories * 0.45) / 4
            break
    }
    return Number(carbs.toFixed(0))
}

export const calculateFats = (
    calories: number,
    macroGoal?: macroGoalEnum,
): number => {
    let fats = 0
    switch (macroGoal) {
        case macroGoalEnum.cutting:
            fats = (calories * 0.2) / 9
            break
        case macroGoalEnum.bulking:
            fats = (calories * 0.2) / 9
            break
        default:
            fats = (calories * 0.25) / 9
            break
    }
    return Number(fats.toFixed(0))
}

export const calculateDailyMacros = (
    tdee: number,
    carbCycle: boolean,
    macroGoal?: macroGoalEnum,
): Macros[] => {
    let newMacros: Macros[] = []

    let calories = calculateCalories(tdee, macroGoal)

    for (const dayOfWeek of enumKeys(dayOfTheWeekEnum)) {
        if (!carbCycle) {
            newMacros.push({
                calories: calories,
                protein: calculateProtein(calories, macroGoal),
                carbs: calculateCarbs(calories, macroGoal),
                fats: calculateFats(calories, macroGoal),
                dayOfTheWeek: dayOfTheWeekEnum[dayOfWeek],
            })
        } else {
            newMacros.push({
                calories: calories,
                protein: calculateProtein(calories, macroGoal),
                carbs: calculateCarbs(calories, macroGoal),
                fats: calculateFats(calories, macroGoal),
                dayOfTheWeek: dayOfTheWeekEnum[dayOfWeek],
            })
        }
    }

    return newMacros
}
