export enum componentNameEnum {
    CREATE_USER = 'createUser',
    VIEW_USER = 'viewUser',
    HOME = 'home',
    PREFERENCES = 'preferences',
    START = 'start',
}

export enum genderEnum {
    male = 'male',
    female = 'female',
}

export enum hoursOfExerciseEnum {
    none,
    oneToThree,
    fourToSix,
    sevenToNine,
    tenPlus,
}

export enum multiplierEnum {
    none = 0,
    low = 0.05,
    medium = 0.1,
    large = 0.15,
}

export enum macroGoalEnum {
    cutting = 'cutting',
    bulking = 'bulking',
    maintain = 'maintain',
}

export enum dayOfTheWeekEnum {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}
