import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
    Button,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'

import { PageRootContext } from '../../pageContext'
import { dayOfTheWeekEnum, macroGoalEnum } from '../../models/enums'
import { Macros } from '../../models/macros'

export const UserMacros = observer((props: any) => {
    const context = React.useContext(PageRootContext)
    const rootStore = context?.rootStore

    let [macroGoal, setMacroGoal] = useState(
        rootStore?.user ? rootStore.user.macroGoal : macroGoalEnum.cutting,
    )
    let [carbCycle, setCarbCycle] = useState(
        rootStore?.user ? rootStore.user.carbCycle : false,
    )

    useEffect(() => {
        if (rootStore?.user?.macroGoal) setMacroGoal(rootStore.user.macroGoal)
    }, [rootStore?.user?.macroGoal])

    useEffect(() => {
        if (rootStore?.user?.carbCycle) setCarbCycle(rootStore.user.carbCycle)
    }, [rootStore?.user?.carbCycle])

    const handleMacroGoal = (
        event: React.MouseEvent<HTMLElement>,
        newGoal: macroGoalEnum | null,
    ) => {
        if (newGoal !== null) {
            setMacroGoal(newGoal)
            rootStore?.updateMacroGoal(newGoal)
        }
    }

    const handleCarbCycle = (
        event: React.MouseEvent<HTMLElement>,
        carbCycle: boolean | null,
    ) => {
        if (carbCycle !== null) {
            setCarbCycle(carbCycle)
            rootStore?.updateCarbCycle(carbCycle)
        }
    }

    return (
        <Paper className="macros" elevation={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h3>Macros</h3>
                </Grid>
                <Grid item xs={6} md={6} className="center-align">
                    <ToggleButtonGroup
                        value={macroGoal}
                        exclusive
                        onChange={handleMacroGoal}
                        aria-label="macro goals"
                        size="small"
                    >
                        <ToggleButton
                            value={macroGoalEnum.cutting}
                            aria-label="left aligned"
                        >
                            Cutting
                        </ToggleButton>
                        <ToggleButton
                            value={macroGoalEnum.bulking}
                            aria-label="centered"
                        >
                            Bulking
                        </ToggleButton>
                        <ToggleButton
                            value={macroGoalEnum.maintain}
                            aria-label="right aligned"
                        >
                            Maintain
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6} md={6} className="center-align">
                    <ToggleButtonGroup
                        value={carbCycle}
                        exclusive
                        onChange={handleCarbCycle}
                        aria-label="macro goals"
                        size="small"
                    >
                        <ToggleButton value={true} aria-label="left aligned">
                            Cycle
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="centered">
                            Constant
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <br />
                </Grid>
                <Grid item xs={12} md={12}>
                    {rootStore?.user ? (
                        <TableContainer>
                            <Table size="small" aria-label="macros table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="boldText">
                                            Day of the Week
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="boldText"
                                        >
                                            Calories
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="boldText"
                                        >
                                            Fat&nbsp;(g)
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="boldText"
                                        >
                                            Carbs&nbsp;(g)
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="boldText"
                                        >
                                            Protein&nbsp;(g)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!rootStore.user?.carbCycle ? (
                                        <TableRow
                                            key={
                                                rootStore.dailyMacros[0]
                                                    .dayOfTheWeek
                                            }
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Every Day
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    rootStore.dailyMacros[0]
                                                        .calories
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {rootStore.dailyMacros[0].fats}
                                            </TableCell>
                                            <TableCell align="right">
                                                {rootStore.dailyMacros[0].carbs}
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    rootStore.dailyMacros[0]
                                                        .protein
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        rootStore.dailyMacros.map(
                                            (macros: Macros) => (
                                                <TableRow
                                                    key={macros.dayOfTheWeek}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="capitalize"
                                                    >
                                                        {macros?.dayOfTheWeek !==
                                                        undefined
                                                            ? dayOfTheWeekEnum[
                                                                  macros
                                                                      .dayOfTheWeek
                                                              ]
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {macros.calories}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {macros.fats}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {macros.carbs}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {macros.protein}
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                </Grid>
            </Grid>
        </Paper>
    )
})
