import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { Favorite, LocationOn, Restore } from '@mui/icons-material'

import { PageRootContext } from '../../pageContext'

export const Home = observer((props: any) => {
    const context = React.useContext(PageRootContext)
    const rootStore = context?.rootStore

    const onLogin = () => {
        rootStore?.loadUser()
    }

    return (
        <div>
            <h1>Home</h1>
            <Paper
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                elevation={3}
            >
                <BottomNavigation
                    showLabels
                    value={''}
                    onChange={(event, newValue) => {
                        //setValue(newValue)
                    }}
                >
                    <BottomNavigationAction
                        label="Recents"
                        icon={<Restore />}
                    />
                    <BottomNavigationAction
                        label="Favorites"
                        icon={<Favorite />}
                    />
                    <BottomNavigationAction
                        label="Archive"
                        icon={<LocationOn />}
                    />
                </BottomNavigation>
            </Paper>
        </div>
    )
})
