import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Paper } from '@mui/material'

import { PageRootContext } from '../../pageContext'

export const Start = observer((props: any) => {
    const context = React.useContext(PageRootContext)
    const rootStore = context?.rootStore

    return (
        <Paper className="start" elevation={3}>
            <h1>Welcome</h1>
            <h3>LET'S GET STARTED</h3>
            <Button variant="contained">Start</Button>
        </Paper>
    )
})
