import React, { FC, ReactNode } from 'react'

import { RootStore, RootStoreObject } from './store/RootStore'

export interface PageContextInterface {
    rootStore: RootStore
}
export const PageRootContext = React.createContext<PageContextInterface | null>(
    {
        rootStore: RootStoreObject
    }
)
export const PageContextProvider = PageRootContext.Provider 

interface Props {
    children?: ReactNode
}

export const PageContext: FC<Props> = ({ children }) => {
    const rootStore = RootStoreObject;
    
    return (<PageContextProvider value={{ rootStore: rootStore }}>{children}</PageContextProvider>)
}