import * as React from 'react'
import { observer } from 'mobx-react-lite'
import {
    AppBar,
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    Toolbar,
    Typography,
    MenuItem,
    Menu,
} from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

import { PageRootContext } from '../pageContext'
import { componentNameEnum } from '../models/enums'

import { UserProfile } from './userProfile/userProfile.component'
import { Home as HomeComponent } from './home/home.component'
import { Preferences } from './preferences/preferences.compnent'
import { Start } from './start/start.component'
import { useContext, useEffect, useState } from 'react'

export const Root = observer(() => {
    const context = useContext(PageRootContext)
    const rootStore = context?.rootStore

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState('recents')
    const [displayComponent, setDisplayComponent] = useState(
        componentNameEnum.START,
    )

    useEffect(() => {
        if (rootStore?.user?.id) {
            setDisplayComponent(componentNameEnum.HOME)
        } else {
            setDisplayComponent(componentNameEnum.CREATE_USER)
        }
    }, [rootStore?.user?.id])

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleUser = () => {
        setDisplayComponent(componentNameEnum.VIEW_USER)
        setAnchorEl(null)
    }

    const handlePreferences = () => {
        setDisplayComponent(componentNameEnum.PREFERENCES)
        setAnchorEl(null)
    }

    const renderComponent = () => {
        switch (displayComponent) {
            case componentNameEnum.CREATE_USER:
                return <UserProfile createUser={true}></UserProfile>
            case componentNameEnum.VIEW_USER:
                return <UserProfile createUser={false}></UserProfile>
            case componentNameEnum.HOME:
                return <HomeComponent></HomeComponent>
            case componentNameEnum.PREFERENCES:
                return <Preferences></Preferences>
            default:
                return <Start></Start>
        }
    }

    return (
        <div className="app">
            <Box sx={{ flexGrow: 1 }}>
                <>
                    <AppBar id="app-bar" position="static">
                        <Toolbar>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1 }}
                            >
                                Beauty or Beast
                            </Typography>
                            {rootStore?.user && (
                                <div>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        className="icon-button"
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                        {rootStore?.user ? (
                                            <div id="user-name">
                                                {rootStore.user.name}
                                            </div>
                                        ) : null}
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleUser}
                                    >
                                        <MenuItem onClick={handleUser}>
                                            User Info
                                        </MenuItem>
                                        <MenuItem onClick={handlePreferences}>
                                            Preferences
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                    {renderComponent()}
                </>
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={rootStore ? rootStore.loading : true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
})
