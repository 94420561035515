import { genderEnum, hoursOfExerciseEnum, macroGoalEnum } from '../models/enums'
import { Macros } from './macros'

export type userWeight = {
    id?: number
    userId?: number
    date: Date
    weight: number
}

export type User = {
    id?: number
    name: string
    height: number
    weight: number
    gender?: genderEnum
    age: number
    hoursOfExercise?: hoursOfExerciseEnum
    bmr: number
    tdee: number
    macroGoal?: macroGoalEnum
    carbCycle: boolean
    protein: number
    carbs: number
    fats: number
    calories: number
}

export const getDefaultUser = (user?: User) => {
    return {
        name: user?.name || '',
        height: user?.height || 0,
        weight: user?.weight || 0,
        gender: user?.gender || undefined,
        hoursOfExercise: user?.hoursOfExercise || hoursOfExerciseEnum.none,
        age: user?.age || 0,
        bmr: user?.bmr || 0,
        tdee: user?.tdee || 0,
        macroGoal: user?.macroGoal || macroGoalEnum.maintain,
        carbCycle: user?.carbCycle || false,
        protein: user?.protein || 0,
        carbs: user?.carbs || 0,
        fats: user?.fats || 0,
        calories: user?.calories || 0,
    }
}

export const defaultUserWeight: userWeight = {
    date: new Date(),
    weight: 0,
}
