import { IDBPDatabase, openDB, deleteDB } from 'idb'
import { User } from '../models/user'

export class DbController {
    private name: string
    private database: any

    public constructor(databaseName: string) {
        this.name = databaseName
    }

    public async createObjectStore(tableNames: string[]) {
        try {
            this.database = await openDB(this.name, 1, {
                upgrade(db: IDBPDatabase) {
                    for (const tableName of tableNames) {
                        if (db.objectStoreNames.contains(tableName)) {
                            continue
                        }
                        db.createObjectStore(tableName, {
                            autoIncrement: true,
                            keyPath: 'id',
                        })
                    }
                },
            })
            console.log('database loaded')
            return true
        } catch (error) {
            return false
        }
    }

    public async getValue(tableName: string, id: number): Promise<any> {
        const tx = this.database.transaction(tableName, 'readonly')
        const store = tx.objectStore(tableName)
        const result = await store.get(id)
        console.log('Get Data ', JSON.stringify(result))
        return result
    }

    public async getAllValue(tableName: string): Promise<any> {
        const tx = this.database.transaction(tableName, 'readonly')
        const store = tx.objectStore(tableName)
        const result = await store.getAll()
        console.log('Get All Data', JSON.stringify(result))
        return result
    }

    public async putValue(tableName: string, value: any): Promise<any> {
        const tx = this.database.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        console.log('Put Data ', JSON.stringify(value))
        const result = await store.put(JSON.parse(JSON.stringify(value)))
        console.log('Put Data ', JSON.stringify(result))
        return result
    }

    public async putBulkValues(tableName: string, values: object[]) {
        const tx = this.database.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        for (const value of values) {
            const result = await store.put(value)
            console.log('Put Bulk Data ', JSON.stringify(result))
        }
        return await this.getAllValue(tableName)
    }

    public async deleteValue(tableName: string, id: number) {
        const tx = this.database.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        const result = await store.get(id)
        if (!result) {
            console.log('Id not found', id)
            return result
        }
        await store.delete(id)
        console.log('Deleted Data', id)
        return id
    }

    public async deleteDatabase() {
        await deleteDB(this.name)
    }

    public async backupDatabase() {
        //await deleteDB(this.name)
    }

    public async restoreDatabase() {
        //await deleteDB(this.name)
    }
}
