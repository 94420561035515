import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { PageRootContext } from '../../pageContext'

export const Preferences = observer((props: any) => {
    const context = React.useContext(PageRootContext)
    const rootStore = context?.rootStore

    const onDelete = () => {
        rootStore?.deleteDatabase()
    }

    return (
        <div className="user">
            <Button variant="contained" onClick={onDelete}>
                Delete Database
            </Button>
        </div>
    )
})
